import React from 'react'
import { css } from 'theme-ui'
import icon from '../../../../../site/content/assets/flexiblocks/icons/full-whatsapp.svg'

const Whastapp = () => {
    return (
        <>
            <div><a href="https://wa.me/5491120401410?text=¡Hola!%20Quiero%20conocer%20más%20de%20Educabot" target="_blank" id="wa_button">
                <div css={css({
                    boxSizing: 'content-box',
                    WebkitBoxSizing: 'content-box',
                    border: '2px solid #25D366',
                    width: '150px',
                    height: '150px',
                    bottom: '-25px',
                    right: '10px',
                    position: 'fixed',
                    WebkitBorderRadius: '100%',
                    MozBorderRadius: '100%',
                    borderRadius: '100%',
                    opacity: 0.5,
                    WebkitAnimation: 'circle-anim 2.4s infinite ease-in-out !important',
                    MozAnimation: 'circle-anim 2.4s infinite ease-in-out !important',
                    msAnimation: 'circle-anim 2.4s infinite ease-in-out !important',
                    OAnimation: 'circle-anim 2.4s infinite ease-in-out !important',
                    animation: 'circle-anim 2.4s infinite ease-in-out !important',
                    WebkitTransition: 'all .5s',
                    MozTransition: 'all .5s',
                    OTransition: 'all .5s',
                    transition: 'all 0.5s',
                    'img-circle:hover': { opacity: 1 },
                    '@keyframes pulse': {
                        '0%': { transform: 'scale(0.9)', opacity: 1 },
                        '50%': { transform: 'scale(1)', opacity: 1 },
                        '100%': { transform: 'scale(0.9)', opacity: 1 }
                    },
                    '@-webkit-keyframes pulse': {
                        '0%': { WebkitTransform: 'scale(0.95)', opacity: 1 },
                        '50%': { WebkitTransform: 'scale(1)', opacity: 1 },
                        '100%': { WebkitTransform: 'scale(0.95)', opacity: 1 }
                    },
                    '@keyframes tossing': {
                        '0%': { transform: 'rotate(-8deg)' },
                        '50%': { transform: 'rotate(8deg)' },
                        '100%': { transform: 'rotate(-8deg)' }
                    },
                    '@-webkit-keyframes tossing': {
                        '0%': { WebkitTransform: 'rotate(-8deg)' },
                        '50%': { WebkitTransform: 'rotate(8deg)' },
                        '100%': { WebkitTransform: 'rotate(-8deg)' }
                    },
                    '@-moz-keyframes circle-anim': {
                        '0%': {
                            MozTransform: 'rotate(0deg) scale(0.5) skew(1deg)',
                            opacity: 0.1,
                            MozOpacity: '.1',
                            WebkitOpacity: '.1',
                            OOpacity: '.1'
                        },
                        '30%': {
                            MozTransform: 'rotate(0deg) scale(0.7) skew(1deg)',
                            opacity: 0.5,
                            MozOpacity: '.5',
                            WebkitOpacity: '.5',
                            OOpacity: '.5'
                        },
                        '100%': {
                            MozTransform: 'rotate(0deg) scale(1) skew(1deg)',
                            opacity: 0.6,
                            MozOpacity: '.6',
                            WebkitOpacity: '.6',
                            OOpacity: '.1'
                        }
                    },
                    '@-webkit-keyframes circle-anim': {
                        '0%': {
                            WebkitTransform: 'rotate(0deg) scale(0.5) skew(1deg)',
                            WebkitOpacity: '.1'
                        },
                        '30%': {
                            WebkitTransform: 'rotate(0deg) scale(0.7) skew(1deg)',
                            WebkitOpacity: '.5'
                        },
                        '100%': {
                            WebkitTransform: 'rotate(0deg) scale(1) skew(1deg)',
                            WebkitOpacity: '.1'
                        }
                    },
                    '@-o-keyframes circle-anim': {
                        '0%': { OTransform: 'rotate(0deg) kscale(0.5) skew(1deg)', OOpacity: '.1' },
                        '30%': { OTransform: 'rotate(0deg) scale(0.7) skew(1deg)', OOpacity: '.5' },
                        '100%': { OTransform: 'rotate(0deg) scale(1) skew(1deg)', OOpacity: '.1' }
                    },
                    '@keyframes circle-anim': {
                        '0%': { transform: 'rotate(0deg) scale(0.5) skew(1deg)', opacity: 0.1 },
                        '30%': { transform: 'rotate(0deg) scale(0.7) skew(1deg)', opacity: 0.5 },
                        '100%': { transform: 'rotate(0deg) scale(1) skew(1deg)', opacity: 0.1 }
                    },
                    '@-moz-keyframes circle-fill-anim': {
                        '0%': { MozTransform: 'rotate(0deg) scale(0.7) skew(1deg)', opacity: 0.2 },
                        '50%': {
                            MozTransform: 'rotate(0deg) -moz-scale(1) skew(1deg)',
                            opacity: 0.2
                        },
                        '100%': { MozTransform: 'rotate(0deg) scale(0.7) skew(1deg)', opacity: 0.2 }
                    },
                    '@-webkit-keyframes circle-fill-anim': {
                        '0%': {
                            WebkitTransform: 'rotate(0deg) scale(0.7) skew(1deg)',
                            opacity: 0.2
                        },
                        '50%': {
                            WebkitTransform: 'rotate(0deg) scale(1) skew(1deg)',
                            opacity: 0.2
                        },
                        '100%': {
                            WebkitTransform: 'rotate(0deg) scale(0.7) skew(1deg)',
                            opacity: 0.2
                        }
                    },
                    '@-o-keyframes circle-fill-anim': {
                        '0%': { OTransform: 'rotate(0deg) scale(0.7) skew(1deg)', opacity: 0.2 },
                        '50%': { OTransform: 'rotate(0deg) scale(1) skew(1deg)', opacity: 0.2 },
                        '100%': { OTransform: 'rotate(0deg) scale(0.7) skew(1deg)', opacity: 0.2 }
                    },
                    '@keyframes circle-fill-anim': {
                        '0%': { transform: 'rotate(0deg) scale(0.7) skew(1deg)', opacity: 0.2 },
                        '50%': { transform: 'rotate(0deg) scale(1) skew(1deg)', opacity: 0.2 },
                        '100%': { transform: 'rotate(0deg) scale(0.7) skew(1deg)', opacity: 0.2 }
                    },
                    '@media (max-width: 500px)': {
                        // width: '110px',
                        // height: '110px',
                        // right: '30px',
                        // bottom: '-5px'
                        display: 'none'
                    }
                })} ></div>
                <div css={css({
                    boxSizing: 'content-box',
                    WebkitBoxSizing: 'content-box',
                    backgroundColor: '#25D366',
                    width: '100px',
                    height: '100px',
                    bottom: '0px',
                    right: '35px',
                    position: 'fixed',
                    WebkitBorderRadius: '100%',
                    MozBorderRadius: '100%',
                    borderRadius: '100%',
                    border: '2px solid transparent',
                    WebkitAnimation: 'circle-fill-anim 2.3s infinite ease-in-out',
                    MozAnimation: 'circle-fill-anim 2.3s infinite ease-in-out',
                    msAnimation: 'circle-fill-anim 2.3s infinite ease-in-out',
                    OAnimation: 'circle-fill-anim 2.3s infinite ease-in-out',
                    animation: 'circle-fill-anim 2.3s infinite ease-in-out',
                    WebkitTransition: 'all .5s',
                    MozTransition: 'all .5s',
                    OTransition: 'all .5s',
                    transition: 'all 0.5s',
                    '@media (max-width: 500px)': {
                        width: '80px',
                        height: '80px',
                        right: '36px',
                        bottom: '2px'
                    }
                })}></div>
                <div css={css({
                    backgroundColor: '#25D366',
                    boxSizing: 'content-box',
                    WebkitBoxSizing: 'content-box',
                    width: '72px',
                    height: '72px',
                    bottom: '14px',
                    right: '49px',
                    position: 'fixed',
                    WebkitBorderRadius: '100%',
                    MozBorderRadius: '100%',
                    borderRadius: '100%',
                    border: '2px solid transparent',
                    opacity: 0.9,
                    '@media (max-width: 500px)': {
                        width: '55px',
                        height: '55px'
                    }
                })}>
                    <div css={css({
                        boxSizing: 'content-box',
                        WebkitBoxSizing: 'content-box',
                        width: '72px',
                        height: '72px',
                        backgroundImage: `url(${icon})`,
                        backgroundPosition: '1.8px 0px',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '70px',
                        animationName: 'tossing',
                        WebkitAnimationName: 'tossing',
                        animationDuration: '1.5s',
                        WebkitAnimationDuration: '1.5s',
                        animationIterationCount: 'infinite',
                        WebkitAnimationIterationCount: 'infinite',
                        '@media (max-width: 500px)': {
                            width: '98%',
                            height: '98%',
                            backgroundSize: '54px',
                        }
                    })}></div>
                </div></a>
            </div>
        </>
    )
}

export default Whastapp
