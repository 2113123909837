import React from 'react'
import { Box, css } from 'theme-ui'
import crossUp from '../../../../../site/content/assets/flexiblocks/homepage/educabot/crossUp.svg'

const Whastapp = () => {
    return (
        <div
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            css={css({
                backgroundColor: '#2A205E',
                boxSizing: 'content-box',
                WebkitBoxSizing: 'content-box',
                width: '45px',
                height: '45px',
                bottom: '14px',
                right: '60px',
                marginBottom: '6rem',
                position: 'fixed',
                WebkitBorderRadius: '100%',
                MozBorderRadius: '100%',
                borderRadius: '100%',
                border: '2px solid transparent',
                opacity: 0.9,
                transition: '0.3s all ease',
                '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: '#D5D3E0'
                },
                '@media (max-width: 500px)': {
                    width: '45px',
                    height: '45px',
                    right: '55px'
                }
            })}>
            <img css={css({
                position: 'relative',
                top: '5px',
                left: '9px',
                '@media (max-width: 500px)': {
                    top: '6px',
                    left: '9px'
                }
            })} src={crossUp} />
        </div>
    )
}

export default Whastapp
